/* eslint-disable @nx/enforce-module-boundaries */
import {
  HttpErrorResponse,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { ENV_TOKEN, EnvironmentModel } from '@carwash-project/modules/core';
import { decryptFn } from '@carwash-project/modules/utils';
import { catchError, throwError } from 'rxjs';

interface IError{
  description?:string;
  message?:string;
  customMessage?:string;
  status?:string
}

export const ErrorEncryptInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
  const environment: EnvironmentModel = inject(ENV_TOKEN);
  
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.error && error.error['encrypted']) {
        let errorData = decryptFn<IError>(error.error['data'],environment.keyEncrypt)

        if(errorData && errorData['description']){
          errorData = {
            ...errorData,
            message:errorData.description,
            customMessage:errorData.description
          }
        }

        const desEncryptedError = new HttpErrorResponse({
          error:errorData,
          headers: error.headers,
          status: error.status,
          statusText: error.statusText,
          url: error.url ?? undefined
        })

        return throwError(() => desEncryptedError);
      }

      return throwError(() => error);
    })
  );
};

