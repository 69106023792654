import { inject, Injectable } from '@angular/core';

import {
  StorageActions,
  StorageState,
  StorageStateModel,
} from '@carwash-project/modules/data-access/storage';
import { Navigate } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { catchError, mergeMap, tap, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { AuthActions } from './auth.actions';

export const AUTH_STATE_TOKEN = new StateToken<AuthStateModel>('auth');

export interface AuthStateModel {
  sessionID: string | null;
  error: unknown;
  loading: boolean;
  isTemporaryPassword: boolean;
  statusCode: string | null;
}

const initialState: AuthStateModel = {
  sessionID: null,
  error: null,
  loading: false,
  isTemporaryPassword: true,
  statusCode: null,
};

@State<AuthStateModel>({
  name: AUTH_STATE_TOKEN,
  defaults: initialState,
})
@Injectable()
export class AuthState {
  private readonly authService = inject(AuthService);

  @Selector([StorageState])
  public static auth(state: AuthStateModel, storageState: StorageStateModel) {
    return {
      loggedIn: storageState.loggedIn,
      token: storageState.token,
      user: storageState.user,
    } as StorageStateModel;
  }

  @Selector([StorageState])
  public static isAuthenticated(
    state: AuthStateModel,
    storageState: StorageStateModel
  ) {
    return storageState?.loggedIn ?? false;
  }

  @Selector([StorageState])
  public static refreshToken(
    state: AuthStateModel,
    storageState: StorageStateModel
  ) {
    return storageState?.refreshToken
  }

  @Selector([StorageState])
  public static profileUser(
    state: AuthStateModel,
    storageState: StorageStateModel
  ) {
    return storageState?.user?.profile_id;
  }

  @Selector([StorageState])
  public static access(
    state: AuthStateModel,
    storageState: StorageStateModel
  ) {
    return storageState?.user?.access;
  }

  @Selector([StorageState])
  public static canAccessPanel(
    state: AuthStateModel,
    storageState: StorageStateModel
  ) {
    return storageState?.user?.accessPanel
  }

  @Selector()
  public static sessionID(state: AuthStateModel) {
    return state.sessionID;
  }

  @Selector()
  public static loading(state: AuthStateModel) {
    return state.loading;
  }

  @Selector()
  public static statusLogin(state: AuthStateModel) {
    return {
      isTemporaryPassword: state.isTemporaryPassword,
      statusCode: state.statusCode,
    };
  }

  /** Action Handle Error */
  @Action(AuthActions.Failure)
  public handleError(
    ctx: StateContext<AuthStateModel>,
    { error }: AuthActions.Failure
  ) {
    ctx.patchState({
      error,
      loading: false,
    });
    return throwError(() => error);
  }

  @Action(AuthActions.SessionID)
  public setSessionID(
    ctx: StateContext<AuthStateModel>,
    { id }: AuthActions.SessionID
  ) {
    ctx.patchState({
      sessionID: id,
    });
  }

  /** Async */
  @Action(AuthActions.Login)
  public onLogin(
    ctx: StateContext<AuthStateModel>,
    { body }: AuthActions.Login
  ) {
    ctx.patchState({ loading: true });
    return this.authService.login(body).pipe(
      tap((payload) => {
        ctx.patchState({
          loading: false,
          statusCode: payload.temporaryPassword ? '04' : payload.statusCode,
          isTemporaryPassword: payload.temporaryPassword,
        });
      }),
      mergeMap((payload) =>
        ctx.dispatch([
          new StorageActions.Save({
            loggedIn: payload.statusCode === '00',
            token: payload.token,
            refreshToken:payload.refreshToken,
            user:
              payload.statusCode === '00' && payload.user
                ? {
                    profile_id: payload.user.role.id,
                    user_id: payload.user.id,
                    access: payload.user.role.access ?? [],
                    accessPanel:payload.user.role.canAccessPanel
                  }
                : null,
            waitTime: null,
            waitTimePayment:null
          }),
          new AuthActions.LoginSuccess(),
        ])
      ),
      catchError((error) => ctx.dispatch(new AuthActions.Failure(error)))
    );
  }

  @Action(AuthActions.Register)
  public onRegister(
    ctx: StateContext<AuthStateModel>,
    { body }: AuthActions.Register
  ) {
    ctx.patchState({ loading: true });
    return this.authService.register(body).pipe(
      tap(() => {
        ctx.patchState({
          loading: false,
        });
      }),
      catchError((error) => ctx.dispatch(new AuthActions.Failure(error)))
    );
  }

  @Action(AuthActions.ConfirmAccount)
  public onConfirmAccount(
    ctx: StateContext<AuthStateModel>,
    { body }: AuthActions.ConfirmAccount
  ) {
    ctx.patchState({ loading: true });
    return this.authService.confirmAccount(body).pipe(
      tap(() => {
        ctx.patchState({
          loading: false,
          statusCode: '00',
          isTemporaryPassword: false,
        });
      }),
      mergeMap((payload) =>
        ctx.dispatch([
          new StorageActions.Save({
            loggedIn: true,
            token: payload.token,
            refreshToken:payload.token,
            user: {
              profile_id: payload.user.role.id,
              user_id: payload.user.id,
              access: payload.user.role.access ?? [],
              accessPanel:payload.user.role.canAccessPanel
            },
            waitTime: null,
            waitTimePayment:null
          }),
          new AuthActions.LoginSuccess(),
        ])
      ),
      catchError((error) => ctx.dispatch(new AuthActions.Failure(error)))
    );
  }

  @Action(AuthActions.ForgotPassword)
  public onForgotPassword(
    ctx: StateContext<AuthStateModel>,
    { body }: AuthActions.ForgotPassword
  ) {
    ctx.patchState({ loading: true });
    return this.authService.ForgotPassword(body).pipe(
      tap(() => {
        ctx.patchState({
          loading: false,
        });
      }),
      catchError((error) => ctx.dispatch(new AuthActions.Failure(error)))
    );
  }

  @Action(AuthActions.ResetPassword)
  public onResetPassword(
    ctx: StateContext<AuthStateModel>,
    { body, token }: AuthActions.ResetPassword
  ) {
    ctx.patchState({ loading: true });
    return this.authService.ResetPassword(body, token).pipe(
      tap(() => {
        ctx.patchState({
          loading: false,
        });
      }),
      catchError((error) => ctx.dispatch(new AuthActions.Failure(error)))
    );
  }

  @Action(AuthActions.ResetPasswordByAdmin)
  public onResetPasswordByAdmin(
    ctx: StateContext<AuthStateModel>,
    { customerId }: AuthActions.ResetPasswordByAdmin
  ) {
    return this.authService.ResetPasswordByAdmin(customerId).pipe(
      catchError((error) => ctx.dispatch(new AuthActions.Failure(error)))
    );
  }

  @Action(AuthActions.LoginSuccess)
  public onLoginSuccess(ctx: StateContext<AuthStateModel>) {
    const { isTemporaryPassword, statusCode: status } = ctx.getState();

    if (isTemporaryPassword && status === '04') {
      ctx.dispatch(new Navigate(['login', 'confirm-account']));
    } else if (!isTemporaryPassword && status === '00') {
      ctx.dispatch(new Navigate(['main', 'dashboard-user']));
    }
  }
}
