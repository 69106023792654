import {
  HttpErrorResponse,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { catchError, switchMap, throwError } from 'rxjs';
import { AuthActions, AuthState } from '../state';
import { AuthService } from '../services/auth.service';
import { StorageActions } from '@carwash-project/modules/data-access/storage';

export const tokenInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
  const store = inject(Store);
  const authService = inject(AuthService);
  const refreshToken = store.selectSnapshot(AuthState.refreshToken);

  if (req.url.includes('refresh-token')) {
    return next(req);
  }

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {
        if (!refreshToken) {
          store.dispatch(new AuthActions.Logout());
          return throwError(() => new Error('La sesión caducó'));
        }

        return authService.refreshToken(refreshToken).pipe(
          switchMap(({ token }) => {
            store.dispatch(new StorageActions.UpdateToken(token));

            const clonedRequest = req.clone({
              headers: req.headers.set('Authorization', `Bearer ${token}`),
            });

            return next(clonedRequest);
          }),
          catchError(() => {
            store.dispatch(new AuthActions.Logout());
            return throwError(() => new Error('La sesión caducó'));
          })
        );
      }

      return throwError(() => error);
    })
  );
};

