/* eslint-disable @nx/enforce-module-boundaries */
import {
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { UserState } from '@carwash-project/modules/data-access/user';
import { Store } from '@ngxs/store';
import { AuthState } from '../state';

const PATHS = ['servicetypes', 'serviceusage', 'verify-plate'];
const ROLE_NAMES = ['Cliente'];

export const branchInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
  const store = inject(Store);
  const branchId = store.selectSnapshot(UserState.branch)?.id;
  const isAuth = store.selectSnapshot(AuthState.isAuthenticated);
  const role = store.selectSnapshot(UserState.role);
  const successRole = ROLE_NAMES.includes(role?.name ?? '');
  const urlInclude = PATHS.some((p) => req.url.includes(p));

  if (isAuth && successRole && !!branchId && urlInclude) {
    const newReq = req.clone({
      setParams: {
        branch: branchId.toString(),
      },
    });

    return next(newReq);
  }

  return next(req);
};
